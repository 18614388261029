import React from "react";

import SubmitAnswers from "./SubmitAnswers";
import ListWinners from "./ListWinners";

import styles from "../../styles/Results/Results.module.css";

const Results = ({
  isAdmin,
  shouldReload,
  reloadCallback,
  setShouldReload,
}) => {
  return (
    <>
      <div className={styles.Results}>
        {isAdmin && (
          <SubmitAnswers
            onSubmitSuccess={reloadCallback}
            setShouldReload={setShouldReload}
          />
        )}
        {/* Pass the shouldReload flag to ListWinners */}
        <ListWinners
          shouldReload={shouldReload}
          isAdmin={isAdmin}
          reloadCallback={reloadCallback}
          setShouldReload={setShouldReload}
        />
      </div>
    </>
  );
};

export default Results;
