import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
  Stack,
} from "@mui/material";
import styles from "../../styles/Leaderboard.module.css";

import Gold from "../../images/gold.png";
import Silver from "../../images/silver.png";
import Bronze from "../../images/bronze.png";
import Tiebreaker from "../../images/tiebreaker.png";

const Leaderboard = ({
  shouldReload,
  disableReload,
  participants,
  guesses,
  winners,
}) => {
  const [leaderboard, setLeaderboard] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tiebreakerCategories = [
    "adaptation",
    "most-anticipated-game",
    "mobile-game",
    "esports-event",
    "esports-game",
  ];

  const participantsLookup = useMemo(() => {
    const lookup = {};
    participants.forEach((participant) => {
      lookup[participant.id] = participant;
    });
    return lookup;
  }, [participants]);

  const calculateLeaderboard = useCallback(() => {
    const leaderboard = Object.keys(guesses).map((participantId) => {
      const participantGuesses = guesses[participantId];
      let score = 0;
      let tiebreakers = 0;

      Object.keys(participantGuesses).forEach((categoryId) => {
        const participantGuess = participantGuesses[categoryId];
        const winner = winners.find((w) => w.categoryId === categoryId);
        if (winner && winner.gameId === participantGuess) {
          if (tiebreakerCategories.includes(winner.categoryId)) {
            tiebreakers += 1;
          } else {
            score += 1;
          }
        }
      });

      return {
        participantId,
        score,
        tiebreakers,
      };
    });

    // Sort leaderboard by score and then by tiebreakers in case of a tie
    leaderboard.sort(
      (a, b) => b.score - a.score || b.tiebreakers - a.tiebreakers
    );

    setLeaderboard(leaderboard);
  }, [guesses, tiebreakerCategories, winners]);

  useEffect(() => {
    if (leaderboard.length === 0) {
      calculateLeaderboard();
    }
  }, [leaderboard, calculateLeaderboard]);

  useEffect(() => {
    calculateLeaderboard();
    disableReload();
  }, [shouldReload, calculateLeaderboard, disableReload]);

  return (
    <>
      <div className={styles.placements}>
        {[1, 0, 2].map((placementIndex) => {
          const person = leaderboard[placementIndex];
          return person ? (
            <div
              key={person.participantId}
              className={`${styles.placementItem} ${
                styles[`place${placementIndex + 1}`]
              }`}
            >
              <img
                className={
                  placementIndex === 1
                    ? styles.silver
                    : placementIndex === 0
                    ? styles.gold
                    : styles.bronze
                }
                src={
                  placementIndex === 1
                    ? Silver
                    : placementIndex === 0
                    ? Gold
                    : Bronze
                }
                alt="Placement"
              />
              <div className={styles.placementRank}>
                <span className={styles.placementText}>
                  {placementIndex === 1
                    ? "2nd"
                    : placementIndex === 0
                    ? "1st"
                    : "3rd"}
                </span>
              </div>
              <div className={styles.placementName}>
                {participantsLookup[person.participantId]?.name}
              </div>
            </div>
          ) : null;
        })}
      </div>
      <div className={styles.leaderboard}>
        <List className={styles.list}>
          <Stack spacing={2}>
            {leaderboard.map((person, index) => (
              <Paper
                key={person.participantId}
                className={styles.participant}
                elevation={index < 3 ? 3 - index : 0}
              >
                <ListItem>
                  <ListItemIcon>
                    <img
                      className={styles.participantImage}
                      src={participantsLookup[person.participantId]?.picture}
                      alt={participantsLookup[person.participantId]?.name}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={participantsLookup[person.participantId]?.name}
                    className={styles.participantName}
                  />

                  <div className={styles.tiebreakerImages}>
                    <ListItemIcon>
                      {person.tiebreakers > 0 &&
                        Array.from({ length: person.tiebreakers }).map(
                          (_, index) => (
                            <img
                              key={index}
                              className={styles.tiebreakerImage}
                              src={Tiebreaker}
                              alt="Tiebreaker"
                            />
                          )
                        )}
                    </ListItemIcon>
                  </div>
                  <div className={styles.score}>{person.score}</div>
                </ListItem>
              </Paper>
            ))}
          </Stack>
        </List>
      </div>
    </>
  );
};

export default Leaderboard;
