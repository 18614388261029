import React, { useState, useEffect } from "react";
import { firestore } from "../../firebase"; // Import your firebase config correctly
import {
  collection,
  getDocs,
  getDoc,
  updateDoc,
  doc,
  setDoc,
  query,
} from "firebase/firestore";

import styles from "../../styles/Results/SubmitAnswers.module.css";
import PacmanLoader from "react-spinners/PacmanLoader";

import {
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Stack,
} from "@mui/material";
import Select from "@mui/material/Select";

// Additional imports
import { toast, ToastContainer } from "react-toastify";

const SubmitAnswers = ({ onSubmitSuccess }) => {
  const [loading, setLoading] = useState(true); // Track loading state
  const [selectedGame, setSelectedGame] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [, setError] = useState("");

  const fetchNominationsForCategory = async (categoryId) => {
    const gamesQuery = query(
      collection(firestore, `categories/${categoryId}/games`)
    );
    const gamesSnapshot = await getDocs(gamesQuery);
    const games = gamesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Sort the games if needed, or manipulate as necessary
    return games;
  };

  const fetchCategoriesAndNominations = async () => {
    setLoading(true);
    try {
      const categoriesCol = collection(firestore, "categories");
      const categoriesSnapshot = await getDocs(categoriesCol);
      const categoriesPromises = categoriesSnapshot.docs.map(async (doc) => {
        const category = {
          id: doc.id,
          ...doc.data(),
        };
        category.games = await fetchNominationsForCategory(category.id);
        return category;
      });

      // Resolve all promises concurrently to efficiently fetch games for all categories
      const fullCategoriesData = await Promise.all(categoriesPromises);

      setCategories(fullCategoriesData);
    } catch (err) {
      console.error(err);
      setError("Error fetching categories"); // Make sure to use setError if you want to display the error in the UI
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategoriesAndNominations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCategorySelect = (event) => {
    console.log("event: ", event);
    setSelectedCategory(event.target.value);
  };

  const handleGameSelect = (event) => {
    console.log("gameId: ", event.target.value);
    setSelectedGame(event.target.value);
  };

  const handleSubmit = async () => {
    console.log(`Category: ${selectedCategory} Game: ${selectedGame}`);

    if (!selectedCategory || !selectedGame) {
      toast.error("Please select both a category and a game!");
      return;
    }

    const winnerDocRef = doc(firestore, "category-winners", selectedCategory);
    const winnerSnapshot = await getDoc(winnerDocRef);

    try {
      if (winnerSnapshot.exists()) {
        // If the category already exists, update the existing document with the new gameId
        await updateDoc(winnerDocRef, {
          gameId: selectedGame,
        });
      } else {
        // If the category does not exist, create a new document with an incremented announcement order
        const winnersCollectionRef = collection(firestore, "category-winners");
        const winnersSnapshot = await getDocs(winnersCollectionRef);
        const announcementOrder = winnersSnapshot.docs.length + 1;

        await setDoc(winnerDocRef, {
          categoryId: selectedCategory,
          gameId: selectedGame,
          announcementOrder: announcementOrder,
        });
      }

      // Notify the user with toastify
      const categoryName = categories.find(
        (cat) => cat.id === selectedCategory
      ).name;
      const gameTitle = categories
        .find((cat) => cat.id === selectedCategory)
        .games.find((game) => game.id === selectedGame).title;

      toast.success(
        `Category "${categoryName}" and nominee "${gameTitle}" saved successfully!`
      );

      // Reset The list
      onSubmitSuccess();
    } catch (error) {
      console.error("Error saving winner", error);
      toast.error("Error saving winner");
    }
  };

  if (loading) {
    return (
      <>
        <div className={styles.loadingContainer}>
          <PacmanLoader
            color="#756eda"
            loading={loading}
            size={25}
            aria-label="Loading..."
            data-testid="PacmanLoader"
          />
        </div>
      </>
    );
  }

  return (
    <div className={styles.container}>
      <Stack direction="column" spacing={2} className={styles.container}>
        <FormControl fullWidth className={styles.formControl}>
          <InputLabel
            id="category-select-label"
            className={styles.categoryLabel}
          >
            Select Category
          </InputLabel>
          <Select
            labelId="category-select-label"
            id="category-select"
            value={selectedCategory}
            label="Select Category"
            onChange={handleCategorySelect}
            className={styles.select}
          >
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedCategory && (
          <FormControl fullWidth className={styles.formControl}>
            <InputLabel id="game-select-label" className={styles.gameLabel}>
              Select Game
            </InputLabel>
            <Select
              labelId="game-select-label"
              id="game-select"
              value={selectedGame}
              label="Select Game"
              onChange={handleGameSelect}
              className={styles.select}
            >
              {categories
                .find((cat) => cat.id === selectedCategory)
                ?.games.map((game) => (
                  <MenuItem key={game.id} value={game.id}>
                    {game.title}
                  </MenuItem>
                ))}
            </Select>

            <Button
              className={styles.submitButton}
              variant="contained"
              onClick={handleSubmit}
              disabled={!selectedCategory || !selectedGame}
            >
              Submit
            </Button>
          </FormControl>
        )}
      </Stack>
      <ToastContainer />
    </div>
  );
};

export default SubmitAnswers;
