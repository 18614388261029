import React from "react";
import styles from "../styles/Countdown.module.css";
import TGALogo from "../images/logo-tga.svg";
const { useState, useEffect } = React;

const Countdown = () => {
  // eslint-disable-next-line no-unused-vars
  const [countdownDate, setCountdownDate] = useState(
    new Date("2023-12-07T17:00:00-08:00").getTime()
  );
  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    setInterval(() => setNewTime(), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNewTime = () => {
    if (countdownDate) {
      const currentTime = new Date().getTime();

      const distanceToDate = countdownDate - currentTime;

      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
      );
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

      days = `0${days}`;
      if (numbersToAddZeroTo.includes(hours)) {
        hours = `0${hours}`;
      } else if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`;
      } else if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`;
      }

      setState({ days: days, hours: hours, minutes, seconds });
    }
  };

  return (
    <>
      <div className={styles.logo} >
        <img src={TGALogo} alt="TGA Logo"/>
      </div>
      <div className={styles.countdownWrapper}>
        <div className={styles.countdownItem}>
          <div className={styles.time}>{state.days || "00"}</div>
          <small className="time-text">Days</small>
        </div>
        <div className={styles.countdownItem}>
          <div className={styles.time}>:</div>
        </div>
        <div className={styles.countdownItem}>
          <div className={styles.time}>{state.hours || "00"}</div>
          <small className="time-text">Hours</small>
        </div>
        <div className={styles.countdownItem}>
          <div className={styles.time}>:</div>
        </div>
        <div className={styles.countdownItem}>
          <div className={styles.time}>{state.minutes || "00"}</div>
          <small className="time-text">Minutes</small>
        </div>
        <div className={styles.countdownItem}>
          <div className={styles.time}>:</div>
        </div>
        <div className={styles.countdownItem}>
          <div className={styles.time}>{state.seconds || "00"}</div>
          <small className="time-text">Seconds</small>
        </div>
      </div>
    </>
  );
};

export default Countdown;
