// OutcomesToggleMenu.jsx
import React from 'react';
import Switch from '@mui/material/Switch';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import styles from '../../styles/Table/OutcomesToggleMenu.module.css';

const OutcomesToggleMenu = ({ participants, visibleParticipants, toggleParticipantVisibility }) => {
  return (
    <Box sx={{ minWidth: 240 }} className={styles.toggleMenu}>
      <List dense>
        {participants.map((participant) => (
          <React.Fragment key={participant.id}>
            <ListItem 
              secondaryAction={
                <Switch
                  edge="end"
                  checked={visibleParticipants[participant.id]}
                  onChange={() => toggleParticipantVisibility(participant.id)}
                />
              }
            >
              <ListItemAvatar>
                <Avatar src={participant.picture} alt={participant.name} />
              </ListItemAvatar>
              <ListItemText primary={participant.name} />
            </ListItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default OutcomesToggleMenu;