import React from "react";
import styles from "../styles/ButtonMenu.module.css";
import { Button } from "@mui/material";

const ButtonMenu = ({ screenSelected }) => {
  return (
    <div className={styles.buttonMenu}>
      <Button
        variant="contained"
        className={styles.button}
        onClick={() => screenSelected("edit")}
        disabled
      >
        Edit
      </Button>
      <Button
        variant="contained"
        className={styles.button}
        onClick={() => screenSelected("overview")}
      >
        Overview
      </Button>
      <Button
        variant="outlined"
        className={styles.logoutButton}
        onClick={() => screenSelected("logout")}
      >
        Logout
      </Button>
    </div>
  );
};

export default ButtonMenu;
