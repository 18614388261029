import React, { useEffect, useState } from "react";
import { firestore } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  orderBy,
  getDoc,
  doc,
} from "firebase/firestore";
import { Tabs, Tab } from "@mui/material";

import Leaderboard from "./Leaderboard";
import Table from "../Table/Table";
import Results from "../Results/Results";
import getGoogleId, { sortCategories } from "../../utils";
import TGALogo from "../../images/logo-tga.svg";

import styles from "../../styles/Overview.module.css";

const Overview = ({ screenSelected }) => {
  const [participants, setParticipants] = useState([]);
  const [categories, setCategories] = useState([]); // [ { id, name, games: [ { id, title } ] }
  const [games, setGames] = useState([]); // { id: title }
  const [visibleParticipants, setVisibleParticipants] = useState({});
  const [guesses, setGuesses] = useState({});
  const [winners, setWinners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [shouldReload, setShouldReload] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true while fetching

      const [
        adminSnapshot,
        categoriesSnapshot,
        gamesSnapshot,
        guessesSnapshot,
      ] = await Promise.all([
        getDocs(collection(firestore, "admin")),
        getDocs(collection(firestore, "categories")),
        getDocs(collection(firestore, "games")),
        getDocs(collection(firestore, "participant-guesses")),
      ]);

      const fetchedParticipants = [];
      for (const guessDoc of guessesSnapshot.docs) {
        const participantId = guessDoc.id;
        const guesses = guessDoc.data();
        // Replace the game IDs in guesses with the corresponding game titles
        const guessTitles = {};
        Object.keys(guesses).forEach((categoryId) => {
          guessTitles[categoryId] =
            games[guesses[categoryId]] || guesses[categoryId];
        });
        // Fetch participant details
        const participantRef = doc(firestore, `participants/${participantId}`);
        const participantSnap = await getDoc(participantRef);
        if (participantSnap.exists()) {
          const { name, picture } = participantSnap.data();
          fetchedParticipants.push({
            id: participantId,
            name,
            picture,
            guesses, // Replace IDs with titles in guesses
            // guesses: guessTitles, // Replace IDs with titles in guesses
          });
        }
      }
      setParticipants(fetchedParticipants);

      // Set the initial visibility of participants to just the the current user
      // Use the user's Google ID to match with the participant IDs
      const participantVisibility = {};
      fetchedParticipants.forEach((participant) => {
        participantVisibility[participant.id] = false;
      });
      participantVisibility[getGoogleId()] = true;

      setVisibleParticipants(participantVisibility);

      // Process categories
      const fetchedCategories = [];
      categoriesSnapshot.forEach((doc) => {
        fetchedCategories.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setCategories(sortCategories(fetchedCategories));

      // Process games
      const fetchedGames = {};
      gamesSnapshot.forEach((doc) => {
        fetchedGames[doc.id] = doc.data().title;
      });
      setGames(fetchedGames);

      // Process guesses
      const fetchedGuesses = {};
      guessesSnapshot.forEach((doc) => {
        fetchedGuesses[doc.id] = doc.data();
      });
      setGuesses(fetchedGuesses);

      // For each id in admin, if any of them matches the current user's id, setIsAdmin to true
      const userGoogleId = getGoogleId();
      adminSnapshot.forEach((doc) => {
        if (doc.id === userGoogleId) {
          setIsAdmin(true);
        }
      });

      setLoading(false); // Set loading to false after fetching
    };

    fetchData().catch(console.error);

    const fetchWinners = async () => {
      const winnersQuerySnapshot = await getDocs(
        query(
          collection(firestore, "category-winners"),
          orderBy("announcementOrder")
        )
      );
      const fetchedWinners = winnersQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setWinners(fetchedWinners);
    };

    fetchWinners().catch(console.error);

    if (shouldReload) {
      fetchWinners().catch(console.error);
      setShouldReload(false); // Reset shouldReload after fetching
    }
    const intervalId = setInterval(() => {
      console.log("Fetching winners...");
      fetchWinners().catch(console.error);
    }, 30000); // Set an interval to fetch winners every 30 seconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Only run this effect if shouldReload changes

  const toggleParticipantVisibility = (participantId) => {
    setVisibleParticipants((prevState) => ({
      ...prevState,
      [participantId]: !prevState[participantId],
    }));
  };

  const handleTabChange = (event, newValue) => {
    setPage(newValue);
    //setShouldReload(true); // Trigger data reloading when changing tabs
  };

  const reloadCallback = () => {
    setShouldReload(true);
  };

  const disableReload = () => {
    setShouldReload(false);
  };

  return (
    <div>
      <div className="tabHeader">
        <img src={TGALogo} alt="The Game Awards" className={styles.logo} />

        <Tabs value={page} onChange={handleTabChange} centered>
          <Tab label="Leaderboard" />
          <Tab label="Table" />
          <Tab label="Announced" />
        </Tabs>
      </div>
      {page === 0 && (
        <Leaderboard
          participants={participants}
          guesses={guesses}
          winners={winners}
          loading={loading}
          shouldReload={shouldReload}
          disableReload={disableReload}
        />
      )}
      {page === 1 && (
        <Table
          participants={participants}
          guesses={guesses}
          games={games}
          categories={categories}
          loading={loading}
          visibleParticipants={visibleParticipants}
          screenSelected={screenSelected}
          categoryWinners={winners}
          toggleParticipantVisibility={toggleParticipantVisibility}
        />
      )}
      {page === 2 && (
        <Results
          shouldReload={shouldReload}
          reloadCallback={reloadCallback}
          isAdmin={isAdmin}
          setShouldReload={setShouldReload}
        />
      )}
    </div>
  );
};

export default Overview;
