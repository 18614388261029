// Background.js
import React from 'react';
import '../styles/Background.css'; // Import the CSS file for styling
import backgroundImage from '../images/tga-bg.jpeg';

const Background = () => {
  return (
    <div
      className="backgroundContainer"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 30%, #042FF0 100%), url(${backgroundImage})`,
      }}
    />
  );
};

export default Background;