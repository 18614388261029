import React, { useEffect } from "react";
import styles from "../../styles/Table/OutcomesTable.module.css";
import tableDragger from "table-dragger";

import PacmanLoader from "react-spinners/PacmanLoader";

import classNames from "classnames";

const OutcomesTable = ({
  screenSelected,
  categories,
  participants,
  loading,
  winners,
  games,
}) => {
  // Define the tiebreaker categories
  const tiebreakerCategories = [
    "adaptation",
    "most-anticipated-game",
    "mobile-game",
    "esports-event",
    "esports-game",
  ];

  useEffect(() => {
    if (!loading && categories.length > 0 && participants.length > 0) {
      // Only initialize tableDragger once the component has all needed data and rendered the table
      const el = document.getElementById("results");
      if (el) {
        const dragger = tableDragger(el, {
          mode: "column", // Drag columns
          onlyBody: false, // Include the header
          animation: 300,
          // omit dragHandler if the whole header cell should be draggable
        });

        dragger.on("drop", function (from, to) {
          console.log(`from: ${from} to: ${to}`);
          // Add logic to handle column arrangement changes here if necessary
        });

        // Clean up if needed when component unmounts or data changes
        return () => {
          // You would add cleanup logic here if the tableDragger library supports it
        };
      }
    }
    // Make sure to include any relevant state items as dependencies
  }, [loading, categories, participants]);

  // Helper function to determine if a guess is correct
  const isWinner = (categoryId, gameTitleGuess) => {
    const winner = winners.find((w) => w.categoryId === categoryId);
    return winner && winner.gameId === gameTitleGuess;
  };

  // Helper function to determine if a category has been called (announced)
  const isCalled = (categoryId) => {
    const winner = winners.find((w) => w.categoryId === categoryId);
    return winner && winner.announcementOrder != null;
  };

  const isTiebreaker = (categoryId) => {
    return tiebreakerCategories.includes(categoryId);
  };

  const regularCategories = categories.filter(
    (category) => !tiebreakerCategories.includes(category.id)
  );
  const tiebreakers = categories.filter((category) =>
    tiebreakerCategories.includes(category.id)
  );

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <PacmanLoader
          color="#756eda"
          loading={loading}
          size={25}
          aria-label="Loading..."
          data-testid="PacmanLoader"
        />
      </div>
    );
  }

  return (
    <>
      {/* <div className={styles.returnButton}>
        <Button
          variant="contained"
          className={styles.button}
          onClick={() => screenSelected("menu")}
        >
          <ArrowBack />
        </Button>
      </div> */}
      <table id="results" className={styles.outcomesTable}>
        <thead>
          <tr>
            <th>Category</th>
            {participants.map((participant) => (
              <th key={participant.id} className="handle">
                <div className={styles.participant}>
                  <img
                    className={styles.participantImage}
                    src={participant.picture}
                    alt={participant.name}
                  />
                  <div className={styles.participantName}>
                    {participant.name}
                  </div>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {regularCategories.map((category) => (
            <tr
              key={category.id}
              className={classNames(styles.category, {
                [styles.goldCategory]: category.id === "game-of-the-year",
                [styles.categoryCalled]: isCalled(category.id),
                [styles.tiebreaker]: isTiebreaker(category.id), // Add this line for the tiebreaker style
              })}
            >
              <td>{category.name}</td>
              {participants.map((participant) => {
                const gameTitleGuess =
                  games[participant.guesses[category.id]] || "-";
                return (
                  <td
                    key={`${category.id}-${participant.id}`}
                    className={classNames({
                      [styles.goldCategory]: category.id === "game-of-the-year",
                      [styles.correctGuess]: isWinner(
                        category.id,
                        participant.guesses[category.id]
                      ),
                    })}
                  >
                    {gameTitleGuess}
                  </td>
                );
              })}
            </tr>
          ))}
          {tiebreakers.map((category) => (
            <tr
              key={category.id}
              className={classNames(styles.category, {
                [styles.goldCategory]: category.id === "game-of-the-year",
                [styles.categoryCalled]: isCalled(category.id),
                [styles.tiebreaker]: isTiebreaker(category.id), // Add this line for the tiebreaker style
              })}
            >
              <td>{category.name}</td>
              {participants.map((participant) => {
                const gameTitleGuess =
                  games[participant.guesses[category.id]] || "-";
                return (
                  <td
                    key={`${category.id}-${participant.id}`}
                    className={classNames({
                      [styles.goldCategory]: category.id === "game-of-the-year",
                      [styles.correctGuess]: isWinner(
                        category.id,
                        participant.guesses[category.id]
                      ),
                    })}
                  >
                    {gameTitleGuess}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default OutcomesTable;
