import React, { useState, useEffect } from "react";
import { firestore } from "../../firebase"; // Import your firebase config correctly
import {
  collection,
  getDocs,
  query,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import styles from "../../styles/Form/GuessForm.module.css";
import PacmanLoader from "react-spinners/PacmanLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getGoogleId from "../../utils";
import { Button, Tooltip, IconButton } from "@mui/material";
import { ArrowBack, Info } from "@mui/icons-material";

const GuessForm = ({ screenSelected }) => {
  const [categories, setCategories] = useState([]);
  const [guesses, setGuesses] = useState({});
  const [loading, setLoading] = useState(true); // Track loading state
  const [, setMadeEdits] = useState(false); // Track if user has made edits
  //const [showWarning, setShowWarning] = useState(false); // Track if user has made edits

  const fetchNominationsForCategory = async (categoryId) => {
    const gamesQuery = query(
      collection(firestore, `categories/${categoryId}/games`)
    );
    const gamesSnapshot = await getDocs(gamesQuery);
    const games = gamesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Sort the games if needed, or manipulate as necessary
    return games;
  };

  const sortCategories = (categories) => {
    // Split categories into normal categories and tiebreaker categories
    const normalCategories = categories.filter(
      (category) => !category.isTiebreaker && category.id !== "game-of-the-year"
    );
    const tiebreakerCategories = categories.filter(
      (category) => category.isTiebreaker
    );
    const gotyCategory = categories.find(
      (category) => category.id === "game-of-the-year"
    );

    // Return an object with all categories separated
    return {
      normalCategories: normalCategories,
      tiebreakerCategories: tiebreakerCategories,
      gotyCategory: gotyCategory, // This may be undefined if not found
    };
  };

  const fetchCategoriesAndNominations = async () => {
    try {
      const categoriesCol = collection(firestore, "categories");
      const categoriesSnapshot = await getDocs(categoriesCol);
      const categoriesData = categoriesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Fetch nominations (games) for each category
      for (let category of categoriesData) {
        category.nominations = await fetchNominationsForCategory(category.id);
      }

      setCategories(sortCategories(categoriesData));

      // Initializes guesses object with all categories
      const initialGuesses = categoriesData.reduce((acc, category) => {
        acc[category.id] = "";
        return acc;
      }, {});
      setGuesses(initialGuesses);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories and games:", error);
      setLoading(false);
    }
  };

  const fetchGuesses = async (participantId) => {
    try {
      const guessesDocRef = doc(
        firestore,
        `participant-guesses/${participantId}`
      );
      const guessesSnapshot = await getDoc(guessesDocRef);

      if (guessesSnapshot.exists()) {
        setGuesses(guessesSnapshot.data());
      } else {
        console.log("No previous guesses found");
      }
    } catch (error) {
      console.error("Error fetching participant's guesses:", error);
    }
  };

  const saveGuesses = async (participantId, guesses) => {
    try {
      const guessesColRef = doc(
        firestore,
        `participant-guesses/${participantId}`
      );
      await setDoc(guessesColRef, guesses);
      toast.success("Answers saved!");
    } catch (error) {
      console.error("Error saving guesses:", error);
      toast.error("Failed to save answers. Please try again.");
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        await fetchCategoriesAndNominations(); // This must finish first
        const googleId = getGoogleId(); // Then get the Google ID
        await fetchGuesses(googleId); // Then fetch guesses for this ID
      } catch (error) {
        console.error("Error setting up initial data:", error);
        setLoading(false); // Make sure to halt loading if there's an error
      }
    };

    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle form submission to Firestore
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(guesses); // For now, just log the guesses

    // Wait for the guesses to be saved, then go to the next screen
    await saveGuesses(getGoogleId(), guesses);

    toast.success("Answers saved!", {
      autoClose: 2000,
      hideProgressBar: true,
    });

    // Wait for the toast to finish, then go to the next screen
    setTimeout(() => {
      screenSelected("menu");
    }, 3000);
  };

  const handleGuessChange = (categoryId, gameId) => {
    setMadeEdits(true);
    setGuesses((prevGuesses) => ({ ...prevGuesses, [categoryId]: gameId }));
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <PacmanLoader
          color="#756eda"
          loading={loading}
          size={25}
          aria-label="Loading..."
          data-testid="PacmanLoader"
        />
      </div>
    );
  }

  const { normalCategories, tiebreakerCategories, gotyCategory } = categories;

  return (
    <>
      <ToastContainer />
      <div className={styles.layoutContainer}>
        <div className={styles.buttonBar}>
          <Button
            onClick={() => screenSelected("menu")}
            className={styles.backButton}
          >
            <ArrowBack />
          </Button>
          <Button
            type="submit"
            className={styles.submitButton}
            form="guessForm"
          >
            Save
          </Button>
        </div>
        <div className={styles.formColumn}>
          <form
            onSubmit={handleSubmit}
            className={styles.guessForm}
            id="guessForm"
          >
            {normalCategories.map((category) => (
              <div key={category.id}>
                <div
                  className={
                    category.id === "game-of-the-year"
                      ? styles.gameOfTheYearHeader
                      : styles.categoryTitleBox
                  }
                >
                  <h3>{category.name}</h3>
                </div>
                {category.nominations.map((game) => (
                  <label key={game.id} className={styles.radioButtonContainer}>
                    <input
                      type="radio"
                      id={`nomination-${game.id}`}
                      name={`category-${category.id}`}
                      value={game.id}
                      checked={guesses[category.id] === game.id}
                      onChange={() => handleGuessChange(category.id, game.id)}
                      className={styles.hiddenRadio}
                    />
                    <span className={styles.customRadio}></span>
                    {game.title}
                  </label>
                ))}
              </div>
            ))}
            {tiebreakerCategories.length > 0 && (
              <>
                <div className={styles.tiebreakerHeader}>
                  <h2>Tiebreakers</h2>
                  <Tooltip
                    title="Tiebreakers are only used if there is a tie in the final results."
                    placement="right"
                  >
                    <IconButton>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </div>
                {tiebreakerCategories.map((category) => (
                  <div key={category.id}>
                    <div className={styles.categoryTitleBox}>
                      <h3>{category.name}</h3>
                    </div>
                    {category.nominations.map((game) => (
                      <label
                        key={game.id}
                        className={styles.radioButtonContainer}
                      >
                        <input
                          type="radio"
                          id={`nomination-${game.id}`}
                          name={`category-${category.id}`}
                          value={game.id}
                          checked={guesses[category.id] === game.id}
                          onChange={() =>
                            handleGuessChange(category.id, game.id)
                          }
                          className={styles.hiddenRadio}
                        />
                        <span className={styles.customRadio}></span>
                        {game.title}
                      </label>
                    ))}
                  </div>
                ))}
              </>
            )}

            {gotyCategory && (
              <>
                <div className={styles.gameOfTheYearHeader}>
                  <h2>Game of the Year</h2>
                </div>
                {gotyCategory.nominations.map((game) => (
                  <label key={game.id} className={styles.radioButtonContainer}>
                    <input
                      type="radio"
                      id={`nomination-${game.id}`}
                      name={`category-${gotyCategory.id}`}
                      value={game.id}
                      checked={guesses[gotyCategory.id] === game.id}
                      onChange={() =>
                        handleGuessChange(gotyCategory.id, game.id)
                      }
                      className={styles.hiddenRadio}
                    />
                    <span className={styles.customRadio}></span>
                    {game.title}
                  </label>
                ))}
              </>
            )}
            <Button type="submit" className={styles.submitButton}>
              {" "}
              Save{" "}
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default GuessForm;
