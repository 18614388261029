export default function getGoogleId() {
  const googleId = localStorage.getItem("google_id");
  if (!googleId) {
    throw new Error("Google ID not found");
  }
  return googleId;
}

export function sortCategories(categories) {
  const gameOfTheYearCategory = categories.find(
    (c) => c.id === "game-of-the-year"
  );
  const otherCategories = categories.filter((c) => c.id !== "game-of-the-year");

  if (gameOfTheYearCategory) {
    return [gameOfTheYearCategory, ...otherCategories];
  }
  return categories;
}
