import React from "react";
import OutcomesTable from "./OutcomesTable";
import OutcomesToggleMenu from "./OutcomesToggleMenu";
import styles from "../../styles/Table/Table.module.css";

const Table = ({
  screenSelected,
  categories,
  guesses,
  games,
  participants,
  categoryWinners,
  toggleParticipantVisibility,
  visibleParticipants,
  loading,
}) => {
  const filterParticipants = participants.filter(
    (participant) => visibleParticipants[participant.id]
  );

  return (
    <div className={styles.tableContainer}>
      <OutcomesTable
        screenSelected={screenSelected}
        participants={filterParticipants}
        categories={categories}
        games={games}
        winners={categoryWinners}
        loading={loading}
      />
      <OutcomesToggleMenu
        participants={participants}
        visibleParticipants={visibleParticipants}
        toggleParticipantVisibility={toggleParticipantVisibility}
      />
    </div>
  );
};

export default Table;
