import React from 'react';
import ProtectedRoute from './components/ProtectedRoute'; // New ProtectedRoute component
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  return (
    <GoogleOAuthProvider clientId="370519918687-66gk1alig6ldd2l8pur2vkh924ig3l84.apps.googleusercontent.com">
      <div className="App">
        <ProtectedRoute />
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;